import {countriesData} from "./data"
import {PhoneEntity, PhoneFormValue} from "./types"

export const DEFAULT_COUNTRY = "US"

export const getPhoneInitialValue = (phone?: PhoneEntity): PhoneFormValue => {
    const selectedItem =
        countriesData.find((item) => item.id === phone?.country) ||
        countriesData.find((item) => item.id === DEFAULT_COUNTRY) ||
        countriesData[0]

    return {
        country: {
            ...selectedItem,
            name: "",
        },
        number: phone?.number,
    }
}

export const getCountryCodeName = (country: {code: string; name: string}): string => {
    return country.code + " " + country.name
}

export const transformPhoneToPayload = (formPhone?: PhoneFormValue): PhoneEntity | undefined => {
    if (!formPhone || !formPhone.number) return undefined

    return {
        code: formPhone?.country.code,
        country: formPhone?.country.id,
        number: formPhone?.number || "",
    }
}

export const getMaskAndPlaceholder = (length?: number): {mask: string; placeholder: string} => {
    switch (length) {
        case 6:
            return {mask: "000 000", placeholder: "___ ___"}
        case 7:
            return {mask: "000 000 0", placeholder: "___ ___ _"}
        case 8:
            return {mask: "000 000 00", placeholder: "___ ___ __"}
        case 9:
            return {mask: "000 000 000", placeholder: "___ ___ ___"}
        case 10:
            return {mask: "000 000 0000", placeholder: "___ ___ ____"}
        case 11:
            return {mask: "000 000 000 00", placeholder: "___ ___ ____ __"}
        default:
            return {mask: "000 000 0000", placeholder: "___ ___ ____"}
    }
}

export const formatPhoneNumber = (phone: {code: string; number?: string}): string => {
    if (!phone.number) return ""

    const {code, number} = phone
    const numberLength = number.length
    const {mask} = getMaskAndPlaceholder(numberLength)

    let formattedNumber = ""
    let numberIndex = 0

    for (const item of mask) {
        if (item === "0") {
            formattedNumber += number[numberIndex]
            numberIndex++
        } else {
            formattedNumber += item
        }
    }

    return `${code} ${formattedNumber}`
}
