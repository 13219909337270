import React, {ReactNode, Suspense, useEffect, useState, useCallback} from "react"
import {DEFAULT_LANGUAGE, getAntdLocale, i18n, initI18n} from "./i18n"
import {APIStatus} from "../types/APITypes"
import {authSelectors} from "../slices/authSlice"
import {useSelector} from "react-redux"
import ScholasticLoader from "../ui/ScholasticLoader/ScholasticLoader"
import {ContainerLayout} from "../ui/ContainerLayout/ContainerLayout"
import {ConfigProvider} from "antd"
import {LocalStorageLocale} from "./localStorage"
import {Locale} from "antd/lib/locale-provider"
import countries from "i18n-iso-countries"
import {CountriesContext} from "../context/countriesContext"

type I18nWrapperProps = {
    ns: string[]
    children: ReactNode
}

export const I18nWrapper: React.FC<I18nWrapperProps> = ({ns, children}) => {
    const locale = useSelector(authSelectors.getLocale)
    const [i18nStatus, setI18nStatus] = useState(APIStatus.Loading)
    const [antdLocale, setAntdLocale] = useState<Locale>()
    const [countryNames, setCountryNames] = useState<{[key: string]: string}>({})
    const unauthorizedUserLocale = LocalStorageLocale.getValue()
    const localeId = locale?.id

    const loadCountries = useCallback(async (lng: string) => {
        try {
            const file = await require(`i18n-iso-countries/langs/${lng}.json`)
            countries.registerLocale(file)
            const names = countries.getNames(lng) || {}
            setCountryNames(names)
        } catch (error) {
            console.warn(`Failed to load countries data for language ${lng}`)
        }
    }, [])

    useEffect(() => {
        const lng = localeId || unauthorizedUserLocale || DEFAULT_LANGUAGE
        initI18n({ns, lng: lng})
            .then(() => {
                return getAntdLocale(lng)
            })
            .then((antdLocale) => {
                setAntdLocale(antdLocale)
                setI18nStatus(APIStatus.Success)
            })
            .catch(() => {
                setI18nStatus(APIStatus.Failure)
            })
    }, [localeId, ns, unauthorizedUserLocale])

    useEffect(() => {
        i18n.on("languageChanged", loadCountries)

        return () => {
            i18n.off("languageChanged", loadCountries)
        }
    }, [loadCountries])

    return (
        <Suspense fallback={<ScholasticLoader />}>
            <CountriesContext.Provider value={{countryNames}}>
                <ConfigProvider locale={antdLocale}>
                    <>
                        {i18nStatus == APIStatus.Initial || i18nStatus == APIStatus.Loading ? (
                            <ContainerLayout>
                                <div style={{marginTop: "8.875rem"}}>
                                    <ScholasticLoader />
                                </div>
                            </ContainerLayout>
                        ) : (
                            children
                        )}
                    </>
                </ConfigProvider>
            </CountriesContext.Provider>
        </Suspense>
    )
}
