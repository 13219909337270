export const countriesData = [
    {id: "AF", code: "+93", numberLength: 9},
    {id: "AL", code: "+355", numberLength: 9},
    {id: "AD", code: "+376", numberLength: 6},
    {id: "AM", code: "+374", numberLength: 8},
    {id: "AR", code: "+54", numberLength: 10},
    {id: "AU", code: "+61", numberLength: 9},
    {id: "AT", code: "+43", numberLength: 9},
    {id: "AZ", code: "+994", numberLength: 9},
    {id: "BH", code: "+973", numberLength: 8},
    {id: "BD", code: "+880", numberLength: 10},
    {id: "BY", code: "+375", numberLength: 9},
    {id: "BE", code: "+32", numberLength: 9},
    {id: "BZ", code: "+501", numberLength: 7},
    {id: "BT", code: "+975", numberLength: 7},
    {id: "BO", code: "+591", numberLength: 8},
    {id: "BA", code: "+387", numberLength: 8},
    {id: "BR", code: "+55", numberLength: 11},
    {id: "BN", code: "+673", numberLength: 7},
    {id: "BG", code: "+359", numberLength: 8},
    {id: "KH", code: "+855", numberLength: 9},
    {id: "CA", code: "+1", numberLength: 10},
    {id: "CL", code: "+56", numberLength: 9},
    {id: "CN", code: "+86", numberLength: 11},
    {id: "CO", code: "+57", numberLength: 10},
    {id: "CR", code: "+506", numberLength: 8},
    {id: "HR", code: "+385", numberLength: 9},
    {id: "CU", code: "+53", numberLength: 8},
    {id: "CY", code: "+357", numberLength: 8},
    {id: "CZ", code: "+420", numberLength: 9},
    {id: "DK", code: "+45", numberLength: 8},
    {id: "DO", code: "+1-809", numberLength: 10},
    {id: "EC", code: "+593", numberLength: 9},
    {id: "SV", code: "+503", numberLength: 8},
    {id: "EE", code: "+372", numberLength: 9},
    {id: "FM", code: "+691", numberLength: 7},
    {id: "FJ", code: "+679", numberLength: 7},
    {id: "FI", code: "+358", numberLength: 9},
    {id: "FR", code: "+33", numberLength: 9},
    {id: "GF", code: "+594", numberLength: 9},
    {id: "GE", code: "+995", numberLength: 9},
    {id: "DE", code: "+49", numberLength: 11},
    {id: "GR", code: "+30", numberLength: 10},
    {id: "GP", code: "+590", numberLength: 10},
    {id: "GT", code: "+502", numberLength: 8},
    {id: "GY", code: "+592", numberLength: 7},
    {id: "HT", code: "+509", numberLength: 8},
    {id: "HN", code: "+504", numberLength: 8},
    {id: "HU", code: "+36", numberLength: 9},
    {id: "IS", code: "+354", numberLength: 7},
    {id: "IN", code: "+91", numberLength: 10},
    {id: "ID", code: "+62", numberLength: 10},
    {id: "IR", code: "+98", numberLength: 10},
    {id: "IQ", code: "+964", numberLength: 9},
    {id: "IE", code: "+353", numberLength: 9},
    {id: "IL", code: "+972", numberLength: 9},
    {id: "IT", code: "+39", numberLength: 10},
    {id: "JP", code: "+81", numberLength: 10},
    {id: "JO", code: "+962", numberLength: 9},
    {id: "KZ", code: "+7", numberLength: 10},
    {id: "KI", code: "+686", numberLength: 7},
    {id: "XK", code: "+383", numberLength: 8},
    {id: "KW", code: "+965", numberLength: 8},
    {id: "KG", code: "+996", numberLength: 8},
    {id: "LA", code: "+856", numberLength: 8},
    {id: "LV", code: "+371", numberLength: 8},
    {id: "LB", code: "+961", numberLength: 8},
    {id: "LI", code: "+423", numberLength: 8},
    {id: "LT", code: "+370", numberLength: 8},
    {id: "LU", code: "+352", numberLength: 9},
    {id: "MK", code: "+389", numberLength: 8},
    {id: "MY", code: "+60", numberLength: 10},
    {id: "MV", code: "+960", numberLength: 7},
    {id: "MT", code: "+356", numberLength: 8},
    {id: "MH", code: "+692", numberLength: 7},
    {id: "MQ", code: "+596", numberLength: 9},
    {id: "MX", code: "+52", numberLength: 10},
    {id: "MD", code: "+373", numberLength: 8},
    {id: "MC", code: "+377", numberLength: 8},
    {id: "MN", code: "+976", numberLength: 8},
    {id: "ME", code: "+382", numberLength: 8},
    {id: "MM", code: "+95", numberLength: 9},
    {id: "NR", code: "+674", numberLength: 7},
    {id: "NP", code: "+977", numberLength: 10},
    {id: "NL", code: "+31", numberLength: 9},
    {id: "NZ", code: "+64", numberLength: 9},
    {id: "NI", code: "+505", numberLength: 8},
    {id: "KP", code: "+850", numberLength: 9},
    {id: "NO", code: "+47", numberLength: 8},
    {id: "OM", code: "+968", numberLength: 8},
    {id: "PK", code: "+92", numberLength: 10},
    {id: "PW", code: "+680", numberLength: 7},
    {id: "PA", code: "+507", numberLength: 8},
    {id: "PG", code: "+675", numberLength: 8},
    {id: "PY", code: "+595", numberLength: 9},
    {id: "PE", code: "+51", numberLength: 9},
    {id: "PH", code: "+63", numberLength: 10},
    {id: "PL", code: "+48", numberLength: 9},
    {id: "PT", code: "+351", numberLength: 9},
    {id: "PR", code: "+1-787", numberLength: 7},
    {id: "QA", code: "+974", numberLength: 8},
    {id: "RO", code: "+40", numberLength: 10},
    {id: "RU", code: "+7", numberLength: 10},
    {id: "FR", code: "+590", numberLength: 8},
    {id: "WS", code: "+685", numberLength: 7},
    {id: "SM", code: "+378", numberLength: 8},
    {id: "SA", code: "+966", numberLength: 9},
    {id: "RS", code: "+381", numberLength: 8},
    {id: "SG", code: "+65", numberLength: 8},
    {id: "SK", code: "+421", numberLength: 9},
    {id: "SI", code: "+386", numberLength: 9},
    {id: "SB", code: "+677", numberLength: 7},
    {id: "KR", code: "+82", numberLength: 10},
    {id: "ES", code: "+34", numberLength: 9},
    {id: "LK", code: "+94", numberLength: 10},
    {id: "PS", code: "+970", numberLength: 8},
    {id: "SR", code: "+597", numberLength: 7},
    {id: "SE", code: "+46", numberLength: 9},
    {id: "CH", code: "+41", numberLength: 9},
    {id: "SY", code: "+963", numberLength: 9},
    {id: "TJ", code: "+992", numberLength: 9},
    {id: "TH", code: "+66", numberLength: 9},
    {id: "TL", code: "+670", numberLength: 7},
    {id: "TO", code: "+676", numberLength: 7},
    {id: "TR", code: "+90", numberLength: 10},
    {id: "TM", code: "+993", numberLength: 9},
    {id: "TV", code: "+688", numberLength: 7},
    {id: "UA", code: "+380", numberLength: 9},
    {id: "AE", code: "+971", numberLength: 9},
    {id: "GB", code: "+44", numberLength: 10},
    {id: "US", code: "+1", numberLength: 10},
    {id: "UY", code: "+598", numberLength: 8},
    {id: "UZ", code: "+998", numberLength: 9},
    {id: "VU", code: "+678", numberLength: 7},
    {id: "VE", code: "+58", numberLength: 10},
    {id: "VN", code: "+84", numberLength: 10},
    {id: "YE", code: "+967", numberLength: 9},
]
