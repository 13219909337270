import i18next from "i18next"
import {initReactI18next} from "react-i18next"
import resourcesToBackend from "i18next-resources-to-backend"
import countries from "i18n-iso-countries"
import moment from "moment"
import {Locale} from "antd/lib/locale-provider"

export const antdLocale = {
    en: "en_US",
    ru: "ru_RU",
    es: "es_ES",
    ca: "ca_ES",
    gl: "gl_ES",
    eu: "es_ES",
    fr: "fr_FR",
    it: "it_IT",
    pl: "pl_PL",
    da: "da_DK",
    fi: "fi_FI",
    de: "de_DE",
    nl: "nl_NL",
    no: "nb_NO",
    sv: "sv_SE",
    kk: "kk_KZ",
    lv: "lv_LV",
    lt: "lt_LT",
    sk: "sk_SK",
    sl: "sl_SI",
    et: "et_EE",
    cs: "cs_CZ",
    hu: "hu_HU",
    bg: "bg_BG",
    hr: "hr_HR",
    uk: "uk_UA",
    ro: "ro_RO",
    el: "el_GR",
    sq: "en_US",
    mk: "mk_MK",
}

export const getAntdLocale = async (lang: string): Promise<Locale> => {
    const locale = antdLocale[lang]

    const module = await import(`antd/lib/locale/${locale}.js`)
    return module.default
}

const defaultNamespaces = [
    "common",
    "glossary",
    "person",
    "home_portal",
    "shared_components",
    "greetings",
    "content",
    "comments",
    "error404",
    "country",
]

type I18nextInstanceConfig = {
    ns: string[]
    lng?: string
}

export const DEFAULT_LANGUAGE = "en"

export const i18n = i18next.createInstance()

export const initI18n = async (config: I18nextInstanceConfig): Promise<void> => {
    const ns = [...defaultNamespaces, ...config.ns]
    const lng = config.lng
    const fallbackLng = DEFAULT_LANGUAGE

    try {
        const file = await require(`i18n-iso-countries/langs/${lng}.json`)
        countries.registerLocale(file)
    } catch (error) {
        console.warn(`Failed to load countries data for language ${lng}`)
    }

    try {
        await import(`moment/locale/${lng}.js`)
    } catch (error) {
        console.warn(`Locale ${lng} for moment.js not found, falling back to ${fallbackLng}`)
    }

    moment.updateLocale(lng || fallbackLng, {
        week: {
            dow: 1,
        },
    })

    moment.locale(lng || fallbackLng)

    await i18n
        .use(
            resourcesToBackend(
                (language: string, namespace: string) =>
                    import(`./locales/${language}/${namespace}.json`)
            )
        )
        .use(initReactI18next)
        .init({
            ns,
            lng: lng, // if you're using a language detector, do not define the lng option
            fallbackLng: fallbackLng,
            interpolation: {
                escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            },
            debug: false,
            preload: lng ? [lng] : [fallbackLng],
            partialBundledLanguages: true,
            react: {
                transSupportBasicHtmlNodes: true,
            },
        })
}
