import React from "react"
import {FormSelect} from "../ReduxForm/FormSelect"
import {Field} from "redux-form"
import styles from "./DateAndTimeSettings.module.scss"
import {useTranslation} from "react-i18next"
import {DateFormat, TimeFormat} from "../../types/userSettingsTypes"
import {i18n} from "../../i18n/i18n"
import moment from "moment"

interface DateAndTimeSettingsProps {
    isMobile?: boolean
}

export const DateAndTimeSettings: React.FC<DateAndTimeSettingsProps> = ({isMobile}) => {
    const {t} = useTranslation(["person"])
    return (
        <div className={styles.dateAndTime}>
            <div className={styles.flexContainer}>
                <div className={styles.label}>
                    <span>{t`person:date_and_time.time`}</span>
                </div>
                <Field
                    name={"timeFormat"}
                    options={getTimeOptions()}
                    fullWidth={isMobile}
                    adaptiveMode={isMobile}
                    component={FormSelect}
                    className={styles.select}
                />
            </div>

            <div className={styles.flexContainer}>
                <div className={styles.label}>
                    <span>{t`person:date_and_time.date`}</span>
                </div>
                <Field
                    name={"dateFormat"}
                    options={getDateOptions()}
                    fullWidth={isMobile}
                    adaptiveMode={isMobile}
                    component={FormSelect}
                    className={styles.select}
                />
            </div>
        </div>
    )
}

export const getDateOptions = () => {
    const dateToFormat = moment("2000-12-25")

    return [
        {
            id: DateFormat.MonthFirst,
            name: i18n.t(`person:date_and_time.date_options.monthFirst`, {
                option1: dateToFormat.format("MM/DD/YYYY"),
                option2: dateToFormat.format("MMM DD, YYYY"),
            }),
        },
        {
            id: DateFormat.DayFirst,
            name: i18n.t(`person:date_and_time.date_options.dayFirst`, {
                option1: dateToFormat.format("DD/MM/YYYY"),
                option2: dateToFormat.format("DD MMM, YYYY"),
            }),
        },
    ]
}

export const getTimeOptions = () => [
    {id: TimeFormat.Hour12, name: i18n.t`person:date_and_time.time_options.hour12`},
    {id: TimeFormat.Hour24, name: i18n.t`person:date_and_time.time_options.hour24`},
]
