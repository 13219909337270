import {createContext, useContext} from "react"

type CountriesContextType = {
    countryNames: {[key: string]: string}
}

export const CountriesContext = createContext<CountriesContextType | undefined>(undefined)

export const useCountriesContext = (): CountriesContextType => {
    const context = useContext(CountriesContext)
    if (!context) {
        throw new Error("useCountriesContext must be used within a CountriesProvider")
    }
    return context
}
