import React, {Suspense} from "react"
import {OptionItem, SelectComponentProps} from "./interfaces"
import {reactLazyRetry} from "../../utilities/retryImport"

const Select = reactLazyRetry(() => import("./SelectComponent"))

export const SelectComponent = <T extends OptionItem = OptionItem>(
    props: SelectComponentProps<T>
) => (
    <Suspense fallback={null}>
        <Select {...props} />
    </Suspense>
)
